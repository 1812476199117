import { getDataTypesAsApiParam } from 'models/dataTypes/helpers';
import { createTableModel } from 'models/modelUtils/tableModelFactory';
import { ApiParams } from 'services/api';
import { getDatabaseList, getDatabaseTableList } from './api';
import {
	DatabaseItem,
	DatabaseTableItem,
	DatabaseTableParams,
	DatabaseTablesTableParams,
} from './dto';
import { getDatabaseInstancesFx } from './effects';
import { databaseInstancesById } from './store';

async function fetchDatabasesApi(apiParamsRaw: ApiParams, signal?: AbortSignal) {
	const { 'data-types': piiTypes, ...apiParams } = apiParamsRaw;

	apiParams['data-types'] = getDataTypesAsApiParam(piiTypes);

	const result = await getDatabaseList(apiParams, signal).then((payload) => {
		return {
			data: payload.databases,
			total: payload.total,
			total_filtered: payload.total_filtered,
		};
	});

	const instances = databaseInstancesById.getState();

	for (const db of result.data) {
		if (!instances[db.instance_id]) {
			await getDatabaseInstancesFx();
			break;
		}
	}

	return result;
}

function createDatabasesModel(initialParams: DatabaseTableParams, fetchFxOnInit: boolean = true) {
	return createTableModel<DatabaseItem, DatabaseTableParams>(
		initialParams,
		fetchDatabasesApi,
		fetchFxOnInit
	);
}

//
// Instantiate model with initial params, but do not fetch data from server.
//

const initialParams: DatabaseTableParams = {
	instance_ids: [],
	'data-types': [],
	search: '',
	sort: { orderBy: 'name', order: 'desc' },
};

const databasesModel = createDatabasesModel(initialParams, false);

function fetchDatabaseTablesApi(apiParamsRaw: ApiParams, signal?: AbortSignal) {
	const { 'data-types': piiTypes, ...apiParams } = apiParamsRaw;

	apiParams['data-types'] = getDataTypesAsApiParam(piiTypes);

	return getDatabaseTableList(apiParams, signal).then((payload) => {
		return {
			data: payload.tables,
			total: payload.total,
			total_filtered: payload.total_filtered,
		};
	});
}

function createDatabaseTablesModel(
	initialDatabaseTablesParams: DatabaseTablesTableParams,
	fetchFxOnInit: boolean = true
) {
	return createTableModel<DatabaseTableItem, DatabaseTablesTableParams>(
		initialDatabaseTablesParams,
		fetchDatabaseTablesApi,
		fetchFxOnInit
	);
}

//
// Instantiate model with initial params, but do not fetch data from server.
//

const initialDatabaseTablesParams: DatabaseTablesTableParams = {
	'data-types': [],
	search: '',
	sort: { orderBy: 'name', order: 'desc' },
};

const databaseTablesModel = createDatabaseTablesModel(initialDatabaseTablesParams, false);

export { databasesModel, databaseTablesModel };
